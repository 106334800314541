import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingTimeSlots: [],
    };
  },
  computed: {
    ...mapFields("timeslot", {
      timeSlotIds: "allIds",
      timeSlotIdsCached: "cacheAllIds",
    }),
    ...mapGetters("timeslot", {
      findTimeSlot: "find",
    }),
  },
  methods: {
    ...mapActions("timeslot", { retrieveTimeSlot: "load" }),
    getTimeSlotItem(itemId, type) {
      if (itemId !== null) {
        let timeSlot = this.resolveTimeSlot(itemId);
        if (timeSlot) {
          return timeSlot[type];
        }
      }
      return '';
    },
    resolveTimeSlot(itemId) {
      if (!this.isTimeSlotLoaded(itemId) && !this.loadingTimeSlots.includes(itemId)) {
        this.loadingTimeSlots.push(itemId);
        this.retrieveTimeSlot(decodeURIComponent(itemId));
      }
      return this.findTimeSlot(itemId);
    },
    isTimeSlotLoaded(itemId) {
      return this.timeSlotIdsCached.includes(itemId);
    },
    resolveTimeSlotAsync(itemId) {
      const poll = resolve => {
        if (this.isTimeSlotLoaded((itemId))) {
          resolve(this.resolveTimeSlot(itemId));
        } else {
          this.resolveTimeSlot(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    }
  }
};
